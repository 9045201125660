import { Link } from "react-router-dom";

const CTASharedTwo = ({ title, desc1, desc2, desc3, image }) => {
  return (
    <section className="overflow-hidden bg-gray-50 items-center sm:grid sm:grid-cols-2">
      <img
        alt="space1"
        src={image}
        className="h-full w-full object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-ss-[30px] md:h-[calc(100%_-_4rem)] md:rounded-ss-[60px]"
        width={250}
        height={100}
      />

      <div className="p-8 md:p-12 lg:px-16 lg:py-24">
        <div className="mx-auto max-w-xl text-left ltr:sm:text-left rtl:sm:text-right">
          <h2 className="text-3xl font-bold text-gray-900 md:text-3xl">{title}</h2>

          <p className="hidden text-gray-500 md:mt-4 md:block text-left">{desc1}</p>
          <p className="hidden text-gray-500 md:mt-4 md:block text-left">{desc2}</p>
          <p className="hidden text-gray-500 md:mt-4 md:block text-left">{desc3}</p>

          <div className="mt-4 md:mt-8">
            <Link
              to="/contact"
              className="inline-block rounded bg-primary-light3 px-12 py-3 text-sm font-medium text-white transition hover:bg-primary-light1 focus:outline-none focus:ring focus:ring-yellow-400"
            >
              Get in touch
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASharedTwo;
