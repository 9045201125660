import { Route, Routes } from "react-router-dom";

import Landing from "./pages/Landing";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
import SharedSpace from "./pages/SharedSpace";
import ScrollToTop from "./hooks/ScrollToTop";
import TeamOffice from "./pages/TeamOffice";
import ExecutiveOffice from "./pages/ExecutiveOffice";
import MeetingHall from "./pages/MeetingHall";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/shared-space" element={<SharedSpace />} />
        <Route exact path="/team-office" element={<TeamOffice />} />
        <Route exact path="/executive-office" element={<ExecutiveOffice />} />
        <Route exact path="/meeting-hall" element={<MeetingHall />} />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
