import { Link } from "react-router-dom";

import FOOTER_DATA from "../data/FooterData";
import SocialMediaIcons from "./SocailMediaIcons";

import Logo from "../assets/logo.svg";

const Footer = () => {
  const Terms = (
    <a className="resume-button" href="/boldspace.pdf" target="_blank" rel="noopener noreferrer">
      Terms of use
    </a>
  );

  return (
    <footer className="bg-primary-main">
      <div className="mx-auto max-w-screen-2xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex justify-start">
            <img src={Logo} width={100} height={51} alt="logo" />
          </div>

          <SocialMediaIcons />
        </div>

        <div className="grid grid-cols-1 gap-8 border-t border-gray-100 pt-8 sm:grid-cols-2 lg:grid-cols-3 lg:pt-16">
          {FOOTER_DATA.map(({ title, items }) => {
            return (
              <div key={title.toString()}>
                <p className="font-medium text-white">{title}</p>
                <ul className="mt-6 space-y-4 text-sm">
                  {items.map((items, index) => {
                    return (
                      <li key={index}>
                        {items.title === "Term of use" ? (
                          <div className="text-white transition hover:opacity-75">{Terms}</div>
                        ) : (
                          <Link to={items.href} className="text-white transition hover:opacity-75">
                            {items.title}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>

        <p className="text-xs text-white">&copy; {new Date().getFullYear()}. BoldSwitch. All rights reserved.</p>
      </div>
    </footer>
  );
};
export default Footer;
