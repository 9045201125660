const BInput = ({ title, placeholder, type }) => {
  return (
    <div>
      <label htmlFor="first" className="sr-only">
        {title}
      </label>

      <input
        type={type}
        className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm focus:outline-0"
        placeholder={placeholder}
      />
    </div>
  );
};

export default BInput;
