import React from "react";
import Layout from "../components/Layout";
import BannerAboutus from "../components/BannerAboutus";
import FeaturedAboutus from "../components/FeaturedAboutus";
import CTAAboutus from "../components/CTAAboutus";

export default function About() {
  return (
    <Layout>
      <BannerAboutus />
      <FeaturedAboutus />
      <CTAAboutus />
    </Layout>
  );
}
