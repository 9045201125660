import React from "react";
import { Link } from "react-router-dom";

import Layout from "../components/Layout";
import CollectUserInfo from "../components/CollectUserInfo";
import NavigationBarTabs from "../components/NavigationBarTabs";
import BannerServices from "../components/BannerServices";
import CTASharedOne from "../components/CTASharedOne";
import CTASharedTwo from "../components/CTASharedTwo";

import ServicesSharedContent from "../components/ServicesSharedContent";

import MeetingHall1 from "../assets/MeetingHall1.svg";
import MeetingHall2 from "../assets/MeetingHall2.svg";

export default function MeetingHall() {
  return (
    <Layout>
      <NavigationBarTabs />
      <BannerServices
        title={
          <>
            The most exciting place to generate ideas and
            <br />
            tackle new projects.
          </>
        }
      />
      <ServicesSharedContent />
      <div className="text-center mt-4 md:mt-8">
        <Link
          to="/contact"
          className="inline-block rounded bg-primary-light3 px-12 py-3 text-sm font-medium text-white transition hover:bg-primary-light1 focus:outline-none focus:ring focus:ring-yellow-400"
        >
          Enquire now
        </Link>
      </div>
      <CTASharedOne
        title={"Your one stop solution for meetings and conference"}
        desc1={"From presentation to discussion, have a productive collaboration with your team."}
        desc2={"Get engaged in brainstorming sessions, presentations and conference calls."}
        desc3={"Host a larger audience all in one space at affordable pricing."}
        image={MeetingHall1}
      />
      <CTASharedTwo
        title={"Connect with your clients and colleagues at a go!"}
        desc1={
          <>
            Host client meetings, team meetings, seminars and <br /> workshops in a spacious and well ventilated space,
            <br />
            equipped with high tech facilities and support.
          </>
        }
        image={MeetingHall2}
      />
      <CollectUserInfo title="Let’s get in touch" subtitle="Select your workstation" />
    </Layout>
  );
}
