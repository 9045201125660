import React from "react";

import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Section from "../components/Section";
import BannerTwo from "../components/BannerTwo";
import Featured from "../components/Featured";
import CTA from "../components/CTA";
import CTAI from "../components/CTAI";
import Location from "../components/Location";
import CollectUserInfo from "../components/CollectUserInfo";

const Landing = () => {
  return (
    <Layout>
      <Banner />
      <Featured />
      <CTA />
      <Section />
      <CTAI />
      <Location />
      <BannerTwo />
      <CollectUserInfo title="Let’s get in touch" subtitle="Select your workstation" />
    </Layout>
  );
};

export default Landing;
