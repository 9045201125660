const Banner = () => {
  return (
    <section className="bg-primary-lightest">
      <div className="mx-auto max-w-screen-xl px-4 py-4 lg:flex lg:items-center">
        <div className="mx-auto max-w-screen-xl text-center">
          <p className="mt-12 mb-6 sm:text-xl/relaxed py-4 text-black-dark">Boldspace – Uniting innovative minds</p>
          <h1 className="text-2xl font-extrabold sm:text-5xl text-black-dark uppercase px-2">The perfect setting</h1>

          <h1 className="text-2xl font-extrabold sm:text-5xl text-black-dark uppercase mt-4">
            <strong className="font-extrabold sm:block mb-2 uppercase text-primary-light1">coworking space</strong>
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Banner;
