import Map from "../assets/map.svg";

const Location = () => {
  return (
    <section className="my-6 overflow-hidden sm:grid sm:grid-cols-2">
      <div className="p-8 md:p-12 lg:px-16 lg:py-24">
        <div className="max-w-xl mx-auto text-left ltr:sm:text-left rtl:sm:text-right">
          <p className="mb-4 font-bold uppercase text-1xl text-primary-light3 md:text-1xl">
            Location
          </p>
          <h2 className="text-3xl font-bold text-gray-900 md:text-3xl">
            Gwarinpa
          </h2>

          <p className="hidden text-left text-gray-500 md:mt-4 md:block">
            No 5 stephen orosanya street, 4th avenue gwarinpa Abuja
          </p>
        </div>
      </div>

      <img
        alt="Map"
        src={Map}
        // className="object-cover w-full h-56 sm:h-full"
        className="h-full w-full object-contain sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-ss-[30px] md:h-[calc(100%_-_4rem)] md:rounded-ss-[60px]"
        width={300}
        height={300}
      />
    </section>
  );
};

export default Location;
