import { Link } from "react-router-dom";

const BannerOverview = () => {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-2xl px-4 py-4 lg:flex lg:items-center bg-white">
        <div className="mx-auto max-w-full text-center">
          <h1 className="text-xl font-extrabold sm:text-5xl text-black-dark">We offer the best work space services</h1>
          <p className="mt-4 mb-4 text-base sm:text-xl/relaxed py-4 text-black-dark">
            Explore our list of available work stations, specially designed to fit your specific needs.
          </p>

          <Link to={"/contact"} className="bg-primary-light3 py-2 px-4 text-white transition hover:bg-primary-light1">
            Get in touch
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BannerOverview;
