const OVERVIEW_DATA = [
  {
    id: 1,
    title: "Shared Space",
    href: "/shared-space",
    image: "service1.webp",
    description: "Stay connected with likeminds and socialize from your own zone in our coworking arena. ",
  },
  {
    id: 2,
    title: "Team Office",
    href: "/team-office",
    image: "service2.svg",
    description:
      "Team work, Make the dream work! Get ensuite with our team offices that permit access to only those on your roster.",
  },
  {
    id: 3,
    title: "Executive Office",
    href: "/executive-office",
    image: "service3.svg",
    description: "Designed for your total privacy and comfort - Take full charge of your own secluded space.",
  },
  {
    id: 4,
    title: "Meeting Hall",
    href: "/meeting-hall",
    image: "service4.svg",
    description:
      "Host your work event such as meetings, training sessions etc at our high tech conference hall, with well equipped digital facilities for a better working and learning experience. ",
  },
];

export default OVERVIEW_DATA;
