import React from "react";
import { Link } from "react-router-dom";

import Layout from "../components/Layout";
import CollectUserInfo from "../components/CollectUserInfo";
import NavigationBarTabs from "../components/NavigationBarTabs";
import BannerServices from "../components/BannerServices";
import CTASharedOne from "../components/CTASharedOne";
import CTASharedTwo from "../components/CTASharedTwo";

import ServicesSharedContent from "../components/ServicesSharedContent";

import TeamOffice1 from "../assets/TeamOffice1.svg";
import TeamOffice2 from "../assets/TeamOffice2.svg";

export default function TeamOffice() {
  return (
    <Layout>
      <NavigationBarTabs />
      <BannerServices
        title={
          <>
            Explore a super B range of fully <br />
            serviced office as a team
          </>
        }
      />
      <ServicesSharedContent />
      <div className="text-center mt-4 md:mt-8">
        <Link
          to="/contact"
          className="inline-block rounded bg-primary-light3 px-12 py-3 text-sm font-medium text-white transition hover:bg-primary-light1 focus:outline-none focus:ring focus:ring-yellow-400"
        >
          Enquire now
        </Link>
      </div>
      <CTASharedOne
        title={"Get en-suite with our team offices"}
        desc1={"Create impact, interact and grow with your teammates from a confined space."}
        desc2={"From a team of 2 to a team of 6, our team office is designed for your productivity and privacy."}
        desc3={"Get your business on the move with affordable amount and flexible bundle."}
        image={TeamOffice1}
      />
      <CTASharedTwo
        title={"Always empowering your needs and collective efforts"}
        desc1={
          <>
            With reduced office cost and maintenance hassle,
            <br />
            Now you can focus on what really
            <br /> matters - your team and your work.
          </>
        }
        image={TeamOffice2}
      />
      <CollectUserInfo title="Let’s get in touch" subtitle="Select your workstation" />
    </Layout>
  );
}
