const ServicesSharedContent = () => {
  return (
    <section>
      <div className="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
        <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
          <div className="mx-auto max-w-lg text-center lg:mx-0 ltr:lg:text-left rtl:lg:text-right">
            <p className="mt-4 text-gray-600">Pricing</p>
            <p className="mt-4 text-gray-600">Shared Space</p>
          </div>

          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
            <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
              <h2 className="mt-2 font-bold">Daily Price</h2>
              <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">5,500 NGN</p>
            </div>
            <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
              <h2 className="mt-2 font-bold">Weekly Price</h2>
              <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">24,500 NGN</p>
            </div>
            <div className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
              <h2 className="mt-2 font-bold">Monthly Price</h2>
              <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">67,000 NGN</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSharedContent;
