import { useState } from "react";

import BInput from "../components/BInput";

const SELECTPLAN = ["Daily", "Weekly", "Monthly"];
const SELECT_OPTION = ["Shared Space", "Team Office", "Executive Office", "Meeting Hall"];

const CollectUserInfo = ({ title, subtitle }) => {
  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState("");
  const [option, setOptions] = useState("");
  return (
    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-2xl">
        <h1 className="text-center text-3xl font-bold text-black-600 sm:text-3xl">{title}</h1>

        <p className="mx-auto mt-4 max-w-md text-center text-gray-500">{subtitle}</p>

        <div className="mb-0 mt-6 space-y-3 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
          <p className="text-left text-lg font-medium">1. Pick an option</p>

          <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-4">
            {SELECT_OPTION.map((item, index) => {
              return (
                <div key={index} onClick={() => setOptions(item)}>
                  <label
                    htmlFor="option1"
                    className={`block flex flex-col items-center gap-4 w-full rounded-lg border border-gray-200 p-2 py-6 text-gray-600 hover:border-primary-light1 peer-checked:border-black peer-checked:bg-black peer-checked:text-white
                    ${option === item ? "border-primary-light1" : ""}
                    `}
                  >
                    <img src={require(`../assets/options/${item}.svg`)} alt={item} />
                    <span className="text-sm">{item}</span>
                  </label>
                </div>
              );
            })}
          </div>

          <button
            type="submit"
            className="block w-full rounded-lg bg-white-600 border px-5 py-3 text-sm font-medium text-gray-400"
          >
            I can’t decide yet, I just want a tour
          </button>

          {/* section two */}
          <p className="text-left text-lg font-medium">2. Fill in your details</p>
          <BInput type={"text"} placeholder={"First name*"} title={"First Name"} />
          <BInput type={"text"} placeholder={"Last name*"} title={"Last Name"} />
          <BInput type={"text"} placeholder={"Email*"} title={"Email"} />
          <BInput type={"number"} placeholder={"080********"} title={"phone"} />

          <div onClick={() => setModal(!modal)} className="relative">
            <div type="text" className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm" placeholder="Company">
              {select ? select : "Select Plan"}
            </div>

            {modal && (
              <div className="absolute end-0 z-10 mt-2 w-full left-0 rounded-md border border-gray-100 bg-white shadow-lg">
                {SELECTPLAN.map((item, index) => {
                  return (
                    <p className="px-4 py-4 hover:bg-gray-300 hover:bg-gray-200" onClick={() => setSelect(item)} key={index}>
                      {item}
                    </p>
                  );
                })}
              </div>
            )}
          </div>

          <button type="submit" className="block w-full rounded-lg bg-primary-light3 px-5 py-3 text-sm font-medium text-white">
            Enquire now
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollectUserInfo;
