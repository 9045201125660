import SectionData from "../data/SectionData";

const Section = () => {
  return (
    <section className="bg-white text-white">
      <div className="max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8  mx-auto">
        <div className="max-w-xl mx-auto">
          <h2 className="text-1xl font-400 sm:text-1xl uppercase text-center" style={{ color: "#AC562A" }}>
            Everything you need under one roof
          </h2>

          <p className="mt-4 font-600 text-center text-3xl font-bold sm:text-4xl " style={{ color: "#101828" }}>
            Key benefits to members
          </p>
        </div>

        <div className="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
          {SectionData.map((item) => (
            <div className="flex items-start gap-4" key={item.id}>
              <span className="shrink-0 rounded-lg bg-white-800 h-2">
                <img src={require(`../assets${item.image}`)} alt={item.title} width={30} height={30} />
              </span>

              <div>
                <h2 className="text-lg font-bold" style={{ color: "#101828" }}>
                  {item.title}
                </h2>

                <p className="mt-1 text-sm text-gray-300" style={{ color: "#344054" }}>
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section;
