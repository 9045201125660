import React from "react";
import { Link } from "react-router-dom";

import Layout from "../components/Layout";
import CollectUserInfo from "../components/CollectUserInfo";
import NavigationBarTabs from "../components/NavigationBarTabs";
import BannerServices from "../components/BannerServices";
import CTASharedOne from "../components/CTASharedOne";
import CTASharedTwo from "../components/CTASharedTwo";

import ServicesSharedContent from "../components/ServicesSharedContent";

import Executive1 from "../assets/Executive1.svg";
import Executive2 from "../assets/s1.svg";

export default function ExecutiveOffice() {
  return (
    <Layout>
      <NavigationBarTabs />
      <BannerServices
        title={
          <>
            The most exciting place to generate ideas and
            <br /> tackle new projects.
          </>
        }
      />
      <ServicesSharedContent />
      <div className="text-center mt-4 md:mt-8">
        <Link
          to="/contact"
          className="inline-block rounded bg-primary-light3 px-12 py-3 text-sm font-medium text-white transition hover:bg-primary-light1 focus:outline-none focus:ring focus:ring-yellow-400"
        >
          Enquire now
        </Link>
      </div>
      <CTASharedOne
        title={<>Take full charge and be your own boss</>}
        desc1={"Discover a world of increased productivity and focus."}
        desc2={"Handle your confidential proposals in outmost privacy and discretion."}
        desc3={"Upgrade your business address."}
        image={Executive1}
      />
      <CTASharedTwo
        title={"Shatter your obstacles"}
        desc1={
          <>
            Achieve greater heights in an aesthetically <br /> pleasing and secluded work station at
            <br /> affordable pricing.
          </>
        }
        image={Executive2}
      />
      <CollectUserInfo title="Let’s get in touch" subtitle="Select your workstation" />
    </Layout>
  );
}
