import { Link } from "react-router-dom";

const BannerTwo = () => {
  return (
    <section className="bg-primary-light2">
      <div className="mx-auto max-w-screen-xl px-4 py-4 lg:flex lg:items-center">
        <div className="mx-auto max-w-xl text-center">
          <h1 className="mt-4 py-4 text-black-dark text-3xl sm:text-4xl">Find the ideal space for you </h1>

          <div className="mt-8 flex flex-wrap justify-center gap-4">
            <Link
              to="/contact"
              className="bg-primary-light3 
              block w-full 
              rounded px-12 py-3 text-sm 
              font-medium text-white 
              hover:bg-primary-light1 hover:text-white 
              focus:outline-none focus:ring
              active:text-opacity-75 sm:w-auto"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerTwo;
