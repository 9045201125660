import { Link } from "react-router-dom";

import Image3 from "../assets/img1.svg";

const CTAI = () => {
  return (
    <section className="overflow-hidden bg-gray-50 sm:grid sm:grid-cols-2">
      <div className="p-8 md:p-12 lg:px-16 lg:py-24">
        <div className="mx-auto max-w-xl text-left ltr:sm:text-left rtl:sm:text-right">
          <p className="text-1xl font-bold text-primary-light3 md:text-1xl mb-4 uppercase">Services</p>
          <h2 className="text-3xl font-bold text-gray-900 md:text-3xl">Your ultimate co working destination</h2>

          <p className="hidden text-gray-500 md:mt-4 md:block text-left">
            Explore a list of our available work stations, specially designed to fit your specific needs.
          </p>

          <div className="mt-4 md:mt-8">
            <Link
              to="/contact"
              className="inline-block rounded bg-primary-light3 text-white px-12 py-4 text-sm font-medium transition hover:bg-primary-light1 focus:outline-none focus:ring focus:ring-yellow-400"
            >
              Enquire Now
            </Link>
          </div>
        </div>
      </div>

      <img
        alt="Image3"
        src={Image3}
        className="h-full w-full object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-ss-[30px] md:h-[calc(100%_-_4rem)] md:rounded-ss-[60px]"
        width={300}
        height={300}
      />
    </section>
  );
};

export default CTAI;
