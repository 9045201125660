const BannerServices = ({ title }) => {
  return (
    <section className="bg-white">
      <div className="mx-auto max-w-screen-2xl px-4 py-4 lg:flex lg:items-center bg-white">
        <div className="mx-auto max-w-full text-center">
          <h1 className="text-1xl font-bold sm:text-5xl text-black-500 leading-2">{title}</h1>
        </div>
      </div>
    </section>
  );
};

export default BannerServices;
