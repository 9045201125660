import React from "react";

import CollectUserInfo from "../components/CollectUserInfo";
import Layout from "../components/Layout";
import NavigationBarTabs from "../components/NavigationBarTabs";
export default function Contact() {
  return (
    <Layout>
      <NavigationBarTabs />
      <CollectUserInfo
        title="Let's keep this conversation going."
        subtitle="Do you have any questions or comments? Don’t hesitate to contact us, we are happy to help you."
      />
    </Layout>
  );
}
