import React from "react";

import Layout from "../components/Layout";
import BannerOverview from "../components/BannerOverview";
import CollectUserInfo from "../components/CollectUserInfo";
import NavigationBarTabs from "../components/NavigationBarTabs";
import SectionOverview from "../components/SectionOverview";

export default function Service() {
  return (
    <Layout>
      <NavigationBarTabs />
      <BannerOverview />
      <SectionOverview />
      <CollectUserInfo title="Let’s get in touch" subtitle="Select your workstation" />
    </Layout>
  );
}
