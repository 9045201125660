import React from "react";
import { Link } from "react-router-dom";

import Layout from "../components/Layout";
import CollectUserInfo from "../components/CollectUserInfo";
import NavigationBarTabs from "../components/NavigationBarTabs";
import BannerServices from "../components/BannerServices";
import CTASharedOne from "../components/CTASharedOne";
import CTASharedTwo from "../components/CTASharedTwo";

import ServicesSharedContent from "../components/ServicesSharedContent";

import SharedSpace1 from "../assets/sharedspace1.svg";
import SharedSpace2 from "../assets/sharedspace2.svg";

export default function SharedSpace() {
  return (
    <Layout>
      <NavigationBarTabs />
      <BannerServices
        title={
          <>
            Work at your pace and comfort from our multiple
            <br />
            workstations, designed to suit your preference.
          </>
        }
      />
      <ServicesSharedContent />
      <div className="text-center mt-4 md:mt-8">
        <Link
          to="/contact"
          className="inline-block rounded bg-primary-light3 px-12 py-3 text-sm font-medium text-white transition hover:bg-primary-light1 focus:outline-none focus:ring focus:ring-yellow-400"
        >
          Enquire now
        </Link>
      </div>
      <CTASharedOne
        title={"Dedicated desk"}
        desc1={"Incubate your next big idea here."}
        desc2={"Connect with like minds."}
        desc3={"A cost effective workstation to boast your productivity."}
        image={SharedSpace1}
      />
      <CTASharedTwo
        title={"Flexible office space and contracts that grow with you."}
        desc1={"A cozy arena to accelerate your productivity."}
        desc2={"Switch up your work routine to achieve excellent results."}
        desc3={"A cost effective workstation that is aesthetically pleasing is just right for you!"}
        image={SharedSpace2}
      />
      <CollectUserInfo title="Let’s get in touch" subtitle="Select your workstation" />
    </Layout>
  );
}
