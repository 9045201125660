import Image4 from "../assets/image4.svg";

const CTA = () => {
  return (
    <div className="h-full w-full rounded-lg flex justify-center">
      <img
        src={Image4}
        alt="Image4"
        //  width={1830} height={730}
      />
    </div>
  );
};

export default CTA;
