import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

export default function Layout({ children }) {
  return (
    <>
      <NavigationBar />
      {children}
      <Footer />
    </>
  );
}
