import { Link, useLocation } from "react-router-dom";

import NAV_TABS_DATA from "../data/NavTabsData";

const NavigationBarTabs = () => {
  const nav = useLocation();

  return (
    <header className="bg-white">
      <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8 bg-white">
        <div className="flex h-16 items-center justify-between my-6 border-b">
          <div className="md:flex md:items-center md:gap-12">
            <nav aria-label="Global" className="">
              <ul className="flex items-center gap-6 text-sm">
                {NAV_TABS_DATA.map((item) => {
                  return (
                    <li key={item.id}>
                      <Link
                        className={`text-gray-500 ${
                          nav.pathname === item.href ? "text-primary-light3" : ""
                        } transition hover:text-gray-500/75 sm:text-sm md:text-sm`}
                        to={item.href}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>

          {/* */}

          <div className="md:flex md:items-center md:gap-12">
            <nav aria-label="Global" className="hidden md:block">
              <ul className="flex items-center gap-6 text-sm">
                <li>
                  <p className="text-gray-500 transition hover:text-gray-500/75">+234 8122220120</p>
                </li>
                <li>
                  <Link
                    className="bg-primary-light3 rounded py-2 px-4 text-white transition hover:bg-primary-light1"
                    to={"/contact"}
                  >
                    Enquire now
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavigationBarTabs;
